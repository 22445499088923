import { memo } from "react";
import { Link } from "react-router-dom";
import NewsletterSignup from "../NewsletterSignup/NewsletterSignup";
import SocialIcons from "../../components/SocialIcons";
import "./Footer.css";

const Footer = () => (
  <div className="footer">
    <div className="footer__content-wrapper">
      <NewsletterSignup />

      <div className="footer__contacts-wrapper">
        <p className="footer__title">CRISTIANA SILVA | IMAGEM ESTRATÉGICA</p>
        <p>geral@cristianasilva.pt</p>
        <p>+351 912 499 133</p>
        <div className="footer__social">
          <SocialIcons />
        </div>
      </div>
    </div>
    <p className="footer__copyright">
      Cristiana Silva © {new Date().getFullYear()}. Todos os direitos
      reservados.
    </p>
    <div className="footer__legal-links-wrapper">
      <Link className="footer__legal-links" to={`/legal/terms-conditions`}>
        Termos e Condições
      </Link>
      <span className="footer__legal-links"> | </span>
      <Link className="footer__legal-links" to={`/legal/privacy-policy`}>
        Política de Privacidade
      </Link>
    </div>
  </div>
);

export default memo(Footer);
